<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl Latest Compare Target Loc
            .header-cta
                .flex.items-center
                    //- button.btn-default(@click="openWatchlist(true)") Watchlist: 100
                        IconSet(type="chevron-down")
                    .block
                        button.btn-primary.m-btn-icon(
                            v-if="checkFeatureByAcl('compare_target', 'create')"
                            @click="openModal(true)"
                        )
                            IconSet(type="add")
                            span.m-hide.ml-3 New Comparison

        .card--table__body
            .box-filter-table.p-4.pt-0
                .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    input.field-style(
                        v-model="keyword"
                        type="text"
                        placeholder="Search Task Name..."
                        @keyup="searchKeyword"
                    )
                    .field-icon
                        IconSet(
                            type="search"
                        )
                //- .field.mr-3( style="min-width: 180px;")
                    select.field-style()
                        option All Operator
                        option Operator 1
                        option Operator 2
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )
                .field.box-find(class="lg:mr-3 lg:mb-0 mr-0 mb-4")
                    .relative
                        .field.relative.w-full
                            input.field-style(
                                placeholder="Select user (write minimum 3 characters)"
                                v-model="user_keyword"
                                @keyup="searchUser()"
                            )
                            .field-icon
                                IconSet(type="search")

                            .box-find-selected(v-if="user_selected")
                                .flex.items-center.justify-between
                                    .flex.items-center
                                        figure.mr-3
                                            //- img.w-6.h-6.rounded-full.object-fit(src="https://randomuser.me/api/portraits/men/41.jpg")
                                        span {{user_keyword}}
                                    button.btn-icon-24(@click="setEmptyUser" v-tooltip="'Remove user'")
                                        IconSet(type="close")

                        .p-4.absolute.container-box-users(v-if="status_users.status === 'loading'")
                            .flex.flex-wrap.items-center.justify-center
                                Spinner(show="true" size="24")
                        .p-4.absolute.container-box-users(v-else-if="user_keyword.length > 2 && list_users.data && list_users.data.length > 0")
                            .small-heading Choose User
                            .box-users
                                .item-user(
                                    v-for="(user, index) in list_users.data"
                                    :key="user.id"
                                    @click="selectUser(user)"
                                )
                                    span {{ user.name }}
                        .p-4.absolute.container-box-users(v-else-if="list_users.data && list_users.data.length == 0")
                            | No data available.

                //- .field(class="lg:mr-3 lg:mb-0 lg:w-64 mr-0 w-full mb-4")
                    .relative.field-style.w-full
                        date-range-picker(
                            ref="picker"
                            :opens="opens"
                            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                            :autoApply="true"
                            v-model="dateRange"
                            @toggle="logEvent('event: open', $event)"
                        )
                            div(
                                slot="input" 
                                slot-scope="picker") 
                                    | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                        .field-icon
                            IconSet(type="calendar")

                .field(class="lg:mr-3 lg:mb-0 lg:w-32 mr-0 w-full mb-4")
                    select.field-style(@change="sizeChange($event)" v-model="items_per_page")
                        option(v-for="(s,index) in size" :key="index" :value="s") {{ s }}
                    .field-icon
                        IconSet(
                            type="chevron-down"
                        )

            .box-table.box-table--target
                table.table
                    thead.m-hide
                        tr
                            th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                    tbody
                        tr(v-if="status_list.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")
                        template(v-else-if="list && list.data && list.data.length > 0")
                            tr(v-for="(data,index) in list.data" :key="data.id")
                                td
                                    routerLink.link(
                                        :to="'/compare-target-loc/'+data.id+'/detail'"
                                        v-if="checkFeatureByAcl('compare_target', 'show')"
                                    ) {{ data.task_name?data.task_name:'Detail' }}
                                    template(v-else) {{ data.task_name?data.task_name:'Detail' }}
                                td {{ toString(data.cases) }}
                                td
                                    .flex.items-center.gap-4
                                        //- img.w-8.h-8.rounded-full.object-fit.overflow-hidden(:src="data.first_target_avatar")
                                        //- span {{ data.first_target_name}}
                                        img.w-8.h-8.rounded-full.object-fit.overflow-hidden(:src="data.first_target.photos && data.first_target.photos[0] ? data.first_target.photos[0].file : getDefaultImage" @error="getDefaultImage")
                                        .flex.flex-col
                                            span {{ data.first_target.name}}
                                            span {{ data.first_target_msisdn }}
                                td
                                    .flex.items-center.gap-4
                                        img.w-8.h-8.rounded-full.object-fit.overflow-hidden(:src="data.second_target.photos && data.second_target.photos[0] ? data.second_target.photos[0].file : getDefaultImage" @error="getDefaultImage")
                                        .flex.flex-col
                                            span {{ data.second_target.name}}
                                            span {{ data.second_target_msisdn }}
                                td(style="padding-right:0px !important")
                                    .text-sm.truncate(style="width: 240px;") {{ data.description?data.description:'-' }}
                                td
                                    //- .w-48
                                    //-     template(v-if="data.notes && data.notes.length > 0")
                                    //-         .text-sm.note(@click="getNotes(true, data.id, data.task_name)") {{data.notes&&data.notes.notes?data.notes.notes:'No notes'}}
                                    //-     template(v-else)
                                    //-         button.link.text-sm(@click="getNotes(true, data.id, data.task_name)") {{data.notes&&data.notes.notes?data.notes.notes:'Add a note'}}
                                    .w-48
                                        template(
                                            v-if="!checkFeatureByAcl('compare_target', 'create') && data?.notes == undefined"
                                        ) -
                                        button.link.text-sm.truncate.text-left(style="width: 240px;"
                                            v-else
                                            @click="getNotes(true, data.id, data.task_name)"
                                        ) {{data?.notes?.notes?data?.notes?.notes:'Add a note'}}

                                td {{ data.created_at?data.created_at:'-' }}
                                td {{ data.created_by && data.created_by.name?data.created_by.name:'-' }}
                                //- td: routerLink.link(:to="'/compare-target-loc/'+data.id+'/detail'") Detail
                                td
                                    .flex.items-center.justify-end.w-full
                                        button.btn-icon-40.m-hide(
                                            v-if="checkFeatureByAcl('compare_target', 'edit')"
                                            @click="openModal(true, data)"
                                            v-tooltip="'Edit'"
                                        )
                                            IconSet(type="edit")
                                        button.btn-icon-40.m-hide(
                                            v-if="checkFeatureByAcl('compare_target', 'delete')"
                                            @click="deleteData(data.id, data.first_target.name+' & '+data.second_target.name)"
                                            v-tooltip="'Delete'"
                                        )
                                            IconSet(type="delete")
                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available
                        //- tr(v-for="item in data")
                            td
                                .flex.items-center.gap-4
                                    img.w-8.h-8.rounded-full.object-fit.overflow-hidden(:src="item.first_target_avatar")
                                    span {{ item.first_target_name}}
                            td
                                .flex.items-center.gap-4
                                    img.w-8.h-8.rounded-full.object-fit.overflow-hidden(:src="item.second_target_avatar")
                                    span {{ item.second_target_name}}
                            td {{item.created_at}}
                            td {{ item.created_by }}
                            td: routerLink.link(to="/compare-target-loc/detail") Detail
            
            .box-table-footer(v-if="status_list.status !== 'loading'")
                .pagination
                    vue-ads-pagination(
                        :total-items="total_data"
                        :items-per-page="items_per_page"
                        :page="page"
                        :max-visible-pages="5"
                    )
                        template(slot="buttons" slot-scope="props")
                            vue-ads-page-button(
                                v-for="(button, key) in props.buttons"
                                :key="key"
                                v-bind="button"
                                :class="{'bg-yellow-dark': button.active}"
                                @page-change="page = button.page"
                                @range-change="start = button.start; end = button.end"
                            )
    //- popup 

    .popup-layer.is-show(v-if="openPopUp")
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl {{edit? 'Edit':'New' }} Compare Target

                button.btn-icon-40(
                    @click="openModal(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .field.w-full.mb-6
                    label.field__label.text-sm.font-bold Task Name<sup>*</sup>
                    .field__form.w-full
                        //- input.field-style.w-full(type="text" v-model="tmpTaskName" @input="inputName" placeholder="Write Task Name here...")
                        //- .py-3.mr-2.message.text-xs.text-red-500(v-if="errorTmpTaskName !== ''") {{ errorTmpTaskName }}
                        
                        input.field-style.w-full(type="text" v-model="model.task_name" placeholder="Write Task Name here...")
                        .py-3.mr-2.ext-xs.text-red-500(v-if="$v.model.task_name.$error") Task Name is required.
                .field.w-full.mb-6
                    label.field__label.text-sm.font-bold Case<sup>*</sup>
                    .field__form.w-full
                        .relative(v-if="status_case_list.status === 'loading'")
                            .field-style
                                .field-style--mirroring.field-style--loading
                                    Spinner(show="true" size="32")
                        .field-multiple-select
                            multiselect(
                                v-if="case_management_list.data && case_management_list.data.length > 0"
                                :value="casesValue",
                                :options="case_management_list.data ? case_management_list.data : []",
                                :multiple="true",
                                placeholder="Select Case",
                                label="operation_name",
                                track-by="id",
                                @input="onChange"
                            )
                        .py-3.mr-2.ext-xs.text-red-500(v-if="$v.model.case_id.$error") This case name is required.
                        //- input.field-style.w-full(type="text" v-model="tmpCase" @input="inputCase" placeholder="Write Case here...")
                        //- .py-3.mr-2.message.text-xs.text-red-500(v-if="errorTmpCase !== ''") {{ errorTmpCase }}
                .field.w-full
                    label.field__label.text-sm.font-bold First Target<sup>*</sup>
                    .relative
                        //- searching
                        .field.w-full(v-if="!targetSelected")
                            input.field-style(
                                type="text"
                                placeholder="Search First Target (write min 3 character)"
                                v-model="target_keyword"
                                @keyup="searchTarget('first')"
                            )
                            //- @input="searchHandler()"
                            .field-icon(v-if="target_keyword.length < 3")
                                IconSet(type="search")                            

                        .field.w-full(v-else)
                            .field-style(style="height: 46px;")
                                .flex.items-center.gap-3.absolute(style="top: 6px")
                                    img.w-8.h-8.rounded-full.object-cover.overflow-hidden(:src="selected_target.photo ? selected_target.photo : getDefaultImage" @error="getDefaultImage")
                                    span {{ selected_target.target_name || selected_target.name }}
                                    
                                button.btn-close-search(@click="clearSelected()")
                                    IconSet(type="x")

                        //- search result
                        .box-search-result(v-if="status_list_target.status === 'loading' && target_keyword.length > 2")
                            .py-4.flex.items-center.justify-center.w-full
                                Spinner(show="true" size="24")
                        .box-search-result(v-else-if="!targetSelected && target_keyword.length > 2 && list_target && list_target.data && list_target.data.length > 0" )
                            span.text-xs.block.pt-4.px-5 Choose a target
                            ul
                                //- li(v-for="item in dataResult")
                                li(v-for="target in list_target.data")
                                    button.w-full( @click="itemSelected(target)")
                                        .flex.items-center.gap-3
                                            img.w-8.h-8.rounded-full.object-cover.overflow-hidden(:src="target.photo ? target.photo : getDefaultImage" @error="getDefaultImage")
                                            span {{ target.target_name }}
                        .box-search-result(v-else-if="list_target.data && list_target.data.length == 0 && target_keyword.length > 2")
                            .p-4.flex.items-center.justify-between
                                span.block "{{ target_keyword }}" not found.
                    template(v-if="selected_target_msisdn")
                        .grid.grid-cols-2.gap-4
                            template(v-for="(data,index) in selected_target_msisdn")
                                .block.w-full.rounded.border.px-2.pt-2.pb-1
                                    .ui-radio.w-full
                                        input(
                                            type="radio" 
                                            name="clue" 
                                            :value="data.value"
                                            v-model="model.first_target_msisdn"
                                        )
                                        //- @click="uncheck(data.value)"
                                        .style-radio
                                            .block
                                                .radio
                                            .flex.items-center.justify-between.w-full
                                                span {{ data.value }}
                                                //- .label-dark.uppercase msisdn
                        //- .ui-checkbox.ui-checkbox--small.mr-8(
                        //-     v-for="(target_msisdn, index) in selected_target_msisdn"
                        //-     :key="index"
                        //- )
                            span {{ target_msisdn.value }}
                    
                    .py-3.mr-2
                        //- .message.text-xs.text-blue-500 {{ $v.model.first_target_id }} 
                        //- .message.text-xs.text-blue-500 {{ $v.model.first_target_msisdn }} 
                        .message.text-xs.text-red-500(v-if="$v.model.first_target_id.$error") First Target is required
                        .message.text-xs.text-red-500(v-else-if="$v.model.first_target_msisdn.$error && !$v.model.first_target_msisdn.required") Please Select MSISDN First Target
                        .message.text-xs.text-red-500(v-if="!$v.model.first_target_msisdn.notSameAs") MSISDN cannot be equal to Second Target
                .field.w-full
                    label.field__label.text-sm.font-bold Second Target<sup>*</sup>
                    .relative
                        //- searching
                        .field.w-full(v-if="!targetSelectedSecond")
                            input.field-style(
                                type="text"
                                placeholder="Search Second Target (write min 3 character)"
                                v-model="second_target_keyword"
                                @keyup="searchTarget('second')"
                            )
                            .field-icon(v-if="second_target_keyword.length < 3")
                                IconSet(type="search")
                            
                        .field.w-full(v-else)
                            .field-style(style="height: 46px;")
                                .flex.items-center.gap-3.absolute(style="top: 6px")
                                    img.w-8.h-8.rounded-full.object-cover.overflow-hidden(:src="selected_target_second.photo ? selected_target_second.photo : getDefaultImage" @error="getDefaultImage")
                                    span {{ selected_target_second.target_name || selected_target_second.name }}
                                    
                                button.btn-close-search(@click="clearSelectedSecond()")
                                    IconSet(type="x")

                        //- search result
                        .box-search-result(v-if="status_list_target.status === 'loading' && second_target_keyword.length > 2")
                            .py-4.flex.items-center.justify-center.w-full
                                Spinner(show="true" size="24")

                        .box-search-result(v-else-if="!targetSelectedSecond && second_target_keyword.length > 2 && list_target && list_target.data && list_target.data.length > 0" )
                            span.text-xs.block.pt-4.px-5 Choose a target
                            ul
                                //- li(v-for="item in dataResult")
                                li(v-for="target in list_target.data")
                                    button.w-full( @click="itemSelectedSecond(target)")
                                        .flex.items-center.gap-3
                                            img.w-8.h-8.rounded-full.object-cover.overflow-hidden(:src="target.photo ? target.photo : getDefaultImage" @error="getDefaultImage")
                                            span {{ target.target_name }}
                        .box-search-result(v-else-if="list_target.data && list_target.data.length == 0 && second_target_keyword.length > 2")
                            .p-4.flex.items-center.justify-between
                                span.block "{{ target_keyword }}" not found.                        
                    template(v-if="selected_target_second_msisdn")
                        .grid.grid-cols-2.gap-4
                            template(v-for="(data,index) in selected_target_second_msisdn")
                                .block.w-full.rounded.border.px-2.pt-2.pb-1
                                    .ui-radio.w-full
                                        input(
                                            type="radio"
                                            name="clue_second" 
                                            :value="data.value"
                                            v-model="model.second_target_msisdn"
                                        )
                                        //- @click="uncheck2(data.value)"
                                        .style-radio
                                            .block
                                                .radio
                                            .flex.items-center.justify-between.w-full
                                                span {{ data.value }}
                
                    .py-3.mr-2
                        //- .message.text-xs.text-red-500(v-if="$v.model.second_target_id.$error || $v.model.second_target_msisdn.$error") Second Target is required &amp; MSISDN cannot be equal to First Target
                        .message.text-xs.text-red-500(v-if="$v.model.second_target_id.$error") Second Target is required
                        .message.text-xs.text-red-500(v-else-if="$v.model.second_target_msisdn.$error && !$v.model.second_target_msisdn.required") Please Select MSISDN Second Target
                        .message.text-xs.text-red-500(v-if="!$v.model.second_target_msisdn.notSameAs") MSISDN cannot be equal to First Target
                .field.w-full.mb-6
                    .field__label
                        label.block.text-sm.font-bold Date Range<sup>*</sup>
                    .field__form
                        .relative.field-style.w-full
                            date-range-picker(
                                v-if="dateRange.startDate && dateRange.endDate" 
                                ref="picker"
                                v-model="dateRange"
                                @update="pickerUpdate($event)" 
                                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm' }"
                                :date-format="dateFormat" 
                                opens="left"
                                :ranges="default_ranges"
                                :autoApply="true"
                                timePicker=true
                            )
                                div(
                                    slot="input" 
                                    slot-scope="picker") 
                                        | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                            .field-icon
                                IconSet(type="calendar")
                        .py-3.mr-2.message.text-xs.text-red-500(v-if="$v.model.from_date.$error || $v.model.to_date.$error") Daterange is required
                            
                .field.w-full.mb-6
                    label.field__label.text-sm.font-bold Description
                    .field__form.w-full
                        textarea.field-style.w-full(v-model="model.description" placeholder="Write a description here...")
                        //- textarea.field-style.w-full(v-model="tmpDesc" placeholder="Write a description here...")
                .field.w-full(v-if="!edit")
                    label.field__label.text-sm.font-bold Notes
                    .field__form.w-full
                        textarea.field-style.w-full(v-model="model.notes" placeholder="Write a notes here...")
                        //- textarea.field-style.w-full(v-model="tmpNotes" placeholder="Write a notes here...")
            .popup-container__footer
                .flex.justify-end.w-full 
                    button.btn-primary-o(@click="openModal(false)") Cancel
                    button.btn-primary.ml-2(@click="submit($event)") Save
                //- .flex.justify-end.w-full
                    template(
                        v-if="tmpTarget1 && tmpTarget1.length>0 && errorTmpTarget1 == '' && tmpTarget2 && tmpTarget2.length>0 && errorTmpTarget2 == '' && tmpTaskName && tmpTaskName.length>0 && errorTmpTaskName == '' && tmpCase && tmpCase.length>0 && errorTmpCase == ''")
                        button.btn-primary(v-if="edit" @click="update()") Update &amp; Search Targets
                        button.btn-primary(v-else @click="create()") Save &amp; Search Targets
                    template(v-else)
                        button.btn-primary.btn-disabled(disabled) {{edit? 'Update':'Save'}} &amp; Search Targets    
    
    .popup-layer(
        v-bind:class="{'is-show' : openNote}"
    )
        .popup-container
            .popup-container__header
                h2.text-xl {{compareTargetSelected}}'s Notes
                button.btn-icon-40(
                    @click="getNotes(false)"
                )
                    IconSet( type="close")

            .popup-container__body
                .mb-4(v-if="checkFeatureByAcl('compare_target', 'create') ")
                    .field.w-full.mb-2
                        textarea.field-style(
                            v-model="tmpNote"
                            placeholder="Write new note here..."
                            style="min-height:60px"
                        )
                    .flex.justify-end
                        template(v-if="tmpNote && tmpNote.length>0")
                            button.btn-primary.btn.small(@click="addNote") Add note
                        template(v-else)
                            button.btn-primary.btn.small.btn-disabled(disabled) Add note
                .mb-4
                    .small-heading History Notes
                    template(v-if="status_notes_list.status == 'loading'")
                        .flex.flex-wrap.items-center.justify-center
                            Spinner(show="true" size="48")

                    .mb-4.pb-4.border-b(v-else-if="notes_list && notes_list.length > 0" v-for="note in notes_list")
                        p.mb-1 {{note.notes}}
                        small.text-xs.opacity-75 {{note.created_at}} • added by {{note.created_by?note.created_by:'-'}}

                    .flex.flex-wrap.items-center.justify-center(v-else)
                        strong.block.text-xs.uppercase.text-light.mb-4 No History Available
                    .block                            
                        template(v-if="status_notes_list.status != 'loading' && (notes_list && notes_list.length > 0)")
                            small.text-xs.opacity-75(v-if="!is_loading_next") {{notes_list.length}} of {{data_pagination.total_data}} items
                            .flex.flex-wrap.items-center.justify-center
                                template(v-if="is_loading_next")
                                    Spinner(show="true" size="48")
                                template(v-else)
                                    template(v-if="data_pagination.current_page<data_pagination.total_page")
                                        button.btn-small.btn-primary(@click="handleNextNotes") Load More    
    //- .popup-layer.is-show(v-if="popUpWatchlist")
        .popup-container
            .popup-container__header
                .flex.items-center
                    h2.text-xl WatchList

                button.btn-icon-40(
                    @click="openWatchlist(false)"
                )
                    IconSet(type="close")

            .popup-container__body
                .mb-2.pb-2
</template>

<script>
import { mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { required, maxLength, sameAs, not } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'

export default {
    name: 'pageCompareTargetIndex',
    components: {
        IconSet,
        Spinner,
        DateRangePicker,
        VueAdsPagination,
        VueAdsPageButton,
        Multiselect,
    },

    data() {
        return {
            tableThead: [
                {
                    name: 'Task Name',
                },{
                    name: 'Case',
                },{
                    name: 'First Target',
                },{
                    name: 'Second Target',
                },{
                    name: 'Description',
                },{
                    name: 'Notes',
                },{
                    name: 'Created At',
                },{
                    name: 'Created By',
                },{
                    name: '',
                },
            ],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: '',
            timeout: null,
            openPopUp: false,
            
            // tmpTaskName: null,
            // tmpCase: null,
            // tmpTarget1: null,
            // first_target_msisdn: null,
            // tmpTarget2: null,
            // second_target_msisdn: null,
            // tmpDesc: '',
            // tmpNotes: '',
            // errorTmpTaskName: '',
            // errorTmpCase: '',
            // errorTmpTarget1: '',
            // errorTmpTarget2: '',
            model:{
                task_name: '',
                case_id: [],
                first_target_id:'',
                first_target_msisdn:'',
                second_target_id:'',
                second_target_msisdn:'',
                description: '',
                from_date:'',
                to_date:'',
                notes:'',
            },

            user_keyword:'',
            user_id:'',
            user_selected:false,
            
            // popUpWatchlist: false,
            targetSelected: false,
            targetSelectedSecond: false,
            
            target_keyword: '',
            second_target_keyword: '',
            
            // from_date: '',
            // to_date: '',
            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [
                    moment().set("hour", 0).set("minute", 0).toDate(),
                    moment().set("hour", 23).set("minute", 59).toDate()
                ],
                'Yesterday': [
                    moment().subtract(1, 'days').set("hour", 0).set("minute", 0).toDate(),
                    moment().set("hour", 23).set("minute", 59).subtract(1, 'days').toDate()
                ],
                'Last 7 days': [
                    moment().subtract(6, 'days').set("hour", 0).set("minute", 0).toDate(),
                    moment().set("hour", 23).set("minute", 59).toDate()
                ],
                'Last week': [
                    moment().subtract(1, 'isoWeek').startOf('isoWeek').set("hour", 0).set("minute", 0).toDate(),
                    moment().subtract(1, 'isoWeek').endOf('isoWeek').set("hour", 23).set("minute", 59).toDate()
                ],
            },

            list_target_data: [],
            page_target: 1,
            total_page: null,
            next_page: false,

            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],
            selected_target: null,
            selected_target_msisdn: null,
            selected_target_second: null,
            selected_target_second_msisdn: null,

            openNote: false,

            compareTargetIdSelected: null,
            compareTargetSelected: null,
            tmpNote: null,
            edit:false,
            editData:null,
        }
    },
    validations() {
        return {
            model: {
                task_name: {
                    required,
                    maxLength: maxLength(255)
                },
                case_id: {
                    required,
                },
                first_target_id:{
                    required,
                },
                first_target_msisdn:{
                    required,
                    notSameAs: not(sameAs(function() { return this.model.second_target_msisdn })),
                    // notSameAs: not(sameAs(this.model.second_target_msisdn)),
                },
                second_target_id:{
                    required,
                },
                second_target_msisdn:{
                    required,
                    notSameAs: not(sameAs(function() { return this.model.first_target_msisdn })),
                    // notSameAs: not(sameAs(this.model.first_target_msisdn)),
                },
                from_date:{
                    required,
                },
                to_date:{
                    required,
                },
                description: {
                    maxLength: maxLength(255)
                },
                notes: {
                    maxLength: maxLength(255)
                },
            },
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('compareTarget', [
            'status_list',
            'list',
            'status_notes_list',
            'notes_list',
            'is_loading_next',
            'data_pagination',
        ]),
        ...mapState({
            status_users: (state) => state.users.status_list,
            list_users: (state) => state.users.list,
            status_list_target: (state) => state.target.status_list,
            list_target: (state) => state.target.list,
            status_case_list: (state) => state.caseManagement.status_list,
            case_management_list: (state) => state.caseManagement.list,
        }),
        route_name() {
            return this.$route.name;
        },
        casesValue: {
            get() {
                if (!this.model.case_id) return null
                // console.log('=====onChange:get')
                // console.log(this.model.case_id)
                try {
                    return this.model.case_id.map(value => this.findOption(value.id)).filter(value => value.id)
                } catch (error) {
                    // console.log('error casesvalue')
                    // console.log(error)
                    return null
                }
            },
            set(v) {
                // console.log('=====casesValue:set')
                // console.log(v)
                this.model.case_id=v.map(value => {
                    let rObj = {}
                    rObj['id'] = value.id
                    return rObj
                })
            }
        },
    },
    watch: {
        route_name(newData, oldData) {
            console.log('=====newData', newData)
            // console.log(newData)
            if (newData === 'pageCompareTargetIndex') {
                if (oldData !== 'pageCompareTargetIndex') {
                    this.defaultValue()
                }
                this.getData();
            }
        },
        list(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
                // this.page = (parseInt(newData.current_page) - 1);
            } else {
                this.total_data = 0;
            }
        },
        items_per_page() {
            this.getData();
        },
        page() {
            this.getData();
        },
        user_keyword(newData) {
            if (newData.length < 3)
                this.$store.commit('users/setList', {});
        },
        // first_target_msisdn(newData, oldData){
        //     console.log('newData 1st')
        //     console.log(newData)
        //     if (newData == this.model.second_target_msisdn) {
        //         console.log('sama 1st')
        //         this.errorTmpTarget1 = 'MSISDN must be different'
        //         this.model.first_target_msisdn = ''
        //     } else if (![null,''].includes(oldData)) {
        //         console.log('new-old 1st')
        //     } else {
        //         console.log('tdk sama 1st')
        //         console.log('tdk sama 1st')
        //         this.errorTmpTarget1 = ''
        //     }
        // },
        // second_target_msisdn(newData, oldData){
        //     console.log('newData 2nd')
        //     console.log(newData)
        //     if (newData == this.model.first_target_msisdn) {
        //         console.log('sama 2nd')
        //         this.errorTmpTarget2 = 'MSISDN must be different'
        //         this.model.second_target_msisdn = ''
        //     } else if (![null,''].includes(oldData)) {
        //         console.log('new-old 2nd')
        //     } else {
        //         console.log('tdk sama 2nd')
        //         this.errorTmpTarget2 = ''
        //     }
        // }
    },
    methods: {
        uncheck(newData, oldData){
            // console.log('newData 1st')
            // console.log(newData)
            if (newData == this.model.second_target_msisdn) {
                // console.log('sama 1st')
                this.errorTmpTarget1 = 'MSISDN must be different'
                this.model.first_target_msisdn = ''
            } else if (![null,''].includes(oldData)) {
                // console.log('new-old 1st')
            } else {
                // console.log('tdk sama 1st')
                // console.log('tdk sama 1st')
                this.errorTmpTarget1 = ''
            }
        },
        uncheck2(newData, oldData){
            // console.log('newData 2nd')
            // console.log(newData)
            if (newData == this.model.first_target_msisdn) {
                // console.log('sama 2nd')
                this.errorTmpTarget2 = 'MSISDN must be different'
                this.model.second_target_msisdn = ''
            } else if (![null,''].includes(oldData)) {
                // console.log('new-old 2nd')
            } else {
                // console.log('tdk sama 2nd')
                this.errorTmpTarget2 = ''
            }
        },
        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },
        async pickerUpdate(e) {
            this.model.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm');
            this.model.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm');    
        },
        getDefaultImage(e) {
            e.target.src = this.imgAvatar;
        },
        searchTarget(target, nextPage=false) {
                // clear timeout variable
                this.$store.commit('target/setList', {});
                clearTimeout(this.timeout);

                var self = this;
                this.timeout = setTimeout(function () {
                        let keyword = null
                        if (target == 'first') {
                            keyword = self.target_keyword
                        } else {
                            keyword = self.second_target_keyword
                        }
                        if (nextPage === false) {
                            self.list_target_data = [];
                            self.page_target = 1;
                            self.total_page = null;
                            self.next_page = false;
                        } else {
                            self.next_page = true;
                        }
                        // no_case_or_cases
                        // var no_case_or_cases = []
                        // for (var i = 0; i < Object.keys(self.model.case_id).length; i++) {
                            // no_case_or_cases.push(self.model.case_id[i].id)
                        // }
                        if (keyword.length > 2) {
                            self.$store.dispatch('target/getList', [10, (nextPage === false ? self.page_target : (self.page_target + 1)), keyword, null, null, null, null, null]);
                        }
                    // }
                }, 650);
        },
        // openModal(open = true) {
        //     // hide scroll bar if true
        //     if(open) {
        //         EventEmit.$emit('overflowHidden', true);
        //     } else {
        //         EventEmit.$emit('overflowHidden', false);
        //     }
        //     this.openPopUp = !this.openPopUp
        //     this.target_keyword = ''
        // },

        // openWatchlist(open = true) {
        //     if(open) {
        //         EventEmit.$emit('overflowHidden', true);
        //     } else {
        //         EventEmit.$emit('overflowHidden', false);
        //     }
        //     this.popUpWatchlist = !this.popUpWatchlist
        // },

        inputName(event){
            const value = event.target.value
            if (String(value).length == 0) {
                this.errorTmpTaskName = "Task Name is required"
            } else {
                this.errorTmpTaskName = ""
            }
        },
        inputCase(event){
            const value = event.target.value
            if (String(value).length == 0) {
                this.errorTmpCase = "Case is required"
            } else {
                this.errorTmpCase = ""
            }
        },

        searchHandler() {
            console.log(this.target_keyword)
        },

        clearSelected() {
            this.target_keyword = ''
            this.targetSelected = false
            this.selected_target = null
            this.selected_target_msisdn = null
            this.model.first_target_id = ''
            this.model.first_target_msisdn = ''
        },
        
        itemSelected(target) {
            this.model.first_target_msisdn = ''
            this.model.first_target_id = target.id
            // if (this.model.first_target_id == this.model.second_target_id) {
            //     this.errorTmpTarget1 = 'Targets must be different'
            //     this.model.first_target_id = ''
            // } else {
            this.errorTmpTarget1 = ''
            this.selected_target = target
            this.selected_target_msisdn = this.filterClues(target)
            this.targetSelected = true
            this.target_keyword = ''
            // }
        },

        filterClues(target){
            let search_msisdn = target.clues;
            return search_msisdn = search_msisdn.filter(function(item) {
                if (item.name === 'msisdn')
                    return item;
            });
        },
        
        searchHandlerSecond() {
            console.log(this.target_keyword)
        },

        clearSelectedSecond() {
            this.second_target_keyword = ''
            this.targetSelectedSecond = false
            this.selected_target_second = null
            this.selected_target_second_msisdn = null
            this.model.second_target_id = ''
            this.model.second_target_msisdn = ''
        },
        
        itemSelectedSecond(target) {
            this.model.second_target_id = target.id
            this.model.second_target_msisdn = ''
            // if (this.model.first_target_id == this.model.second_target_id) {
            //     this.errorTmpTarget2 = 'Targets must be different'
            //     this.model.second_target_id = ''
            // } else {
            this.errorTmpTarget2 = ''
            this.selected_target_second = target
            this.selected_target_second_msisdn = this.filterClues(target)
            this.targetSelectedSecond = true
            this.second_target_keyword = ''
            // }
        },
        saveTarget() {
            this.$router.push('/compare-target-loc/detail');
            EventEmit.$emit('overflowHidden', false);
        },
        searchUser() {
            // clear timeout variable
            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                if (self.user_keyword.length > 2) {
                    self.$store.commit('users/setList', {});
                    self.$store.dispatch('users/getList', [30, 1, self.user_keyword]);
                }
            }, 650);
        },
        selectUser(user){
            this.user_keyword = user.name
            this.user_id = user.id
            this.$store.commit('users/setList', {});
            this.user_selected = true
            this.getData()
        },
        setEmptyUser() {
            this.user_keyword = ''
            this.user_id=''
            this.user_selected = false
            this.getData()
        },
        defaultValue() {
            this.items_per_page = 10;
            this.page = 0;
            this.keyword = '';
            this.$store.commit('compareTarget/setList', {});
        },
        getData() {
            console.log('=====getData')
            // for dispatch get data
            if (this.route_name === 'pageCompareTargetIndex') {
                this.$store.dispatch('compareTarget/getList', [this.items_per_page, (this.page + 1), this.keyword, this.user_id]);
                // if (this.list.total_page && this.list.total_page > 1)
                    // this.pagination();
            }
        },
        pageChange(page) {
            this.page = page;
        },
        sizeChange() {
            this.page = 0;
        },
        searchKeyword() {
            clearTimeout(this.timeout);            
            var self = this;
            this.timeout = setTimeout(function () {
                self.page = 0;
                self.$store.commit('compareTarget/setList', {});
                self.getData();
            }, 650);
        },
        openModal(open = true, data=null) {
            // hide scroll bar if true
            if(open) {
                this.$store.dispatch('caseManagement/getList', [100, 1, null, 'active']);
                EventEmit.$emit('overflowHidden', true);
                // this.searchTarget();
            } else {
                EventEmit.$emit('overflowHidden', false);
            }
            if(data){
                this.edit = true
                this.editData = data
                // this.tmpTaskName = data.task_name
                // this.tmpCase = data.case
                // this.tmpDesc = data.description
                this.model.task_name = data.task_name
                this.model.case_id = data.case_id
                this.model.description = data?.description || ''
                
                this.targetSelected = true
                this.model.first_target_id = data.first_target_id
                this.model.first_target_msisdn = data.first_target_msisdn
                // this.model.first_target_id = data.first_target_id
                // this.model.first_target_msisdn = data.first_target_msisdn
                this.selected_target = data.first_target
                this.selected_target_msisdn = this.filterClues(data.first_target)

                this.targetSelectedSecond = true
                this.model.second_target_id = data.second_target_id
                this.model.second_target_msisdn = data.second_target_msisdn
                // this.model.second_target_id = data.second_target_id
                // this.model.second_target_msisdn = data.second_target_msisdn
                this.selected_target_second = data.second_target
                this.selected_target_second_msisdn = this.filterClues(data.second_target)
                try {
                    if (data.from_date && data.to_date) {
                        this.dateRange.startDate = moment(data.from_date, 'YYYY-MM-DD HH:mm').toDate();
                        this.dateRange.endDate = moment(data.to_date, 'YYYY-MM-DD HH:mm').toDate();
                        
                        this.model.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm');
                        this.model.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm');
                    } else {
                        const default_date = this.$store.getters['config/getDefaultDate'](14);
                        this.dateRange.startDate = moment(default_date.from_date + ' 00:00', 'YYYY-MM-DD HH:mm').toDate();
                        this.dateRange.endDate = moment(default_date.to_date + ' 23:59', 'YYYY-MM-DD HH:mm').toDate();

                        this.model.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm');
                        this.model.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm');
                    }
                } catch (error) {
                    console.log('error', error)
                }
            } else {
                this.edit = false
                this.editData = data
                this.clearSelected()
                this.clearSelectedSecond()
                this.setEmptyModel(true)
                const default_date = this.$store.getters['config/getDefaultDate'](14);
                this.dateRange.startDate = moment(default_date.from_date + ' 00:00', 'YYYY-MM-DD HH:mm').toDate();
                this.dateRange.endDate = moment(default_date.to_date + ' 23:59', 'YYYY-MM-DD HH:mm').toDate();

                this.model.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm');
                this.model.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm');
            }

            this.openPopUp = !this.openPopUp
            // this.model.first_target_id = ''
            // this.model.second_target_id = ''
            // this.errorTmpTarget1=''
            // this.errorTmpTarget2=''
            // this.target_keyword=''
            // this.second_target_keyword=''
        },
        setEmptyModel(all=true) {
            this.$nextTick(() => { this.$v.$reset(); });
            for (let key in Object.keys(this.model)) {
                if (all === true)
                    if(["case_id"].indexOf(key) > -1)
                        this.model[Object.keys(this.model)[key]] = [];
                    else
                        this.model[Object.keys(this.model)[key]] = '';
            }
        },
        onChange (value) {
            this.casesValue = value
        },
        findOption(value) {
            // console.log('=====findOption')
            // console.log(value)
            const opt = this.case_management_list.data
            try {
                return opt.find(option => option['id'] === value)
            } catch (error) {
                // console.log('error')
                // console.log(error)
                return opt
            }
        },
        submit(e) {
            e.preventDefault();
            this.$v.$touch();
            console.log(this.$v)
            console.log(this.$v.model)
            console.log(this.$v.model.$invalid)
            if (this.$v.model.$invalid) {
                for (let key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes("$")) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // EventEmit.$emit('error', 'Please fill the form correctly.');
                // EventEmit.$emit('showLoading', false);
                window.scrollTo(0,0);
                return;
            } 
            if (this.edit) {
                this.update()                
            } else {
                this.create()
            }
        },
        create(){
            EventEmit.$emit('showLoading', true);
            let model_data = Object.keys(this.model).map(
                (key) => (["case_id"].indexOf(key) > -1)?[JSON.stringify(this.model[key])]:this.model[key]
            );
            this.$store.dispatch('compareTarget/create', model_data)
            // this.$store.dispatch('compareTarget/create', [this.tmpTaskName, this.tmpCase, this.model.first_target_id, this.model.first_target_msisdn, this.model.second_target_id, this.model.second_target_msisdn, this.tmpDesc, this.model.from_date, this.model.to_date, this.tmpNotes])
            .then((resp) => {
                if (resp.result === 'success') {
                    this.$router.push({ name: 'pageCompareTargetDetail', params: { id: resp.data.data.id } });
                } else {
                    if (resp.data.message) {
                        EventEmit.$emit('error', resp.data.message);
                    } else {
                        EventEmit.$emit('error');
                    }
                }
            });
            this.openModal(false);
        },
        update(){
            EventEmit.$emit('showLoading', true);
            let id = this.editData.id
            // this.$store.dispatch('compareTarget/update', [id, this.tmpTaskName, this.tmpCase, this.model.first_target_id, this.model.first_target_msisdn, this.model.second_target_id, this.model.second_target_msisdn, this.tmpDesc, this.model.from_date, this.model.to_date])
            let model_data = Object.keys(this.model).map(
                (key) => (["case_id"].indexOf(key) > -1)?[JSON.stringify(this.model[key])]:this.model[key]
            );
            model_data.unshift(id)
            this.$store.dispatch('compareTarget/update', model_data)
            .then((resp) => {
                if (resp.result === 'success') {
                    this.$router.push({ name: 'pageCompareTargetDetail', params: { id: id } });
                } else {
                    if (resp.data.message) {
                        EventEmit.$emit('error', resp.data.message);
                    } else {
                        EventEmit.$emit('error');
                    }
                }
            });
            this.openModal(false);
        },
        deleteData(id, name) {
            this.$swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete?',
                text: 'Compare Target Loc : ' + name,
                confirmButtonText: 'Yes!',
                confirmButtonColor: '#DD6B55',
                showCancelButton: true,
                allowOutsideClick: false,
            })
            .then((result) => {

                if (result.isConfirmed) {
                    EventEmit.$emit('showLoading', true);

                    this.$store.dispatch('compareTarget/delete', [id])
                    .then((resp) => {
                        if (resp === 'success') {
                            this.page = 0;

                            this.getData();

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Data successfully deleted!',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message) {
                                EventEmit.$emit('error', resp.data.message);
                            } else {
                                EventEmit.$emit('error');
                            }
                        }
                    });
                }
            });
        },
        getNotes(open=true, compare_target_id=null,task_name=null) {
            this.$store.commit('compareTarget/setNotesList', {});
            if(open){
                this.compareTargetIdSelected = compare_target_id
                this.compareTargetSelected = task_name
                this.$store.dispatch('compareTarget/getNotesList', [this.compareTargetIdSelected]);
                this.openNote = true
                EventEmit.$emit('overflowHidden', true);
            } else {
                this.setEmptyNotes();
                this.setDefaulDataNotes();
                EventEmit.$emit('overflowHidden', false);
            }
        },
        handleNextNotes() {
            // console.log(compare_target_id)
            this.$store.commit('compareTarget/setIsLoadingNext', true);        
            this.$store.dispatch('compareTarget/getNotesList', [this.compareTargetIdSelected, true]);
        },
        addNote(){
            EventEmit.$emit('showLoading', true);
            this.$store.dispatch('compareTarget/createNotes', [this.compareTargetIdSelected, this.tmpNote])
                .then((resp) => {
                    if (resp.result === 'success') {
                        // this.getNotes(false)
                        this.tmpNote = null
                        this.setDefaulDataNotes()

                        this.getNotes(true, this.compareTargetIdSelected, this.compareTargetSelected);
                        this.getData();

                        this.$swal.fire({
                            icon: 'success',
                            title: 'Note successfully added!',
                            timer: 3000,
                        });
                    } else {
                        if (resp.data.message) {
                            EventEmit.$emit('error', resp.data.message);
                        } else {
                            EventEmit.$emit('error');
                        }
                    }
                });
        },
        setEmptyNotes(){
            this.compareTargetIdSelected = null
            this.compareTargetSelected = null
            this.tmpNote = null
            this.openNote = false
        },
        setDefaulDataNotes(){
            // console.log('else')
            // this.dataNotes = [];
            // this.totalNotes = 0;
            // this.limitNotes = 10;
            // this.pageNotes=0;
            // this.totalPageNotes=1;
            const dataPagination = { 
                current_page: 1,
                has_next: false,
                has_previous: false,
                next_page_number: 1,
                per_page: 10,
                previous_page_number: null,
                total_data: 0,
                total_page: 1,
            }
            this.$store.commit('compareTarget/setDataPagination', dataPagination);
        },
    },

    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        }
    },
    mounted() {
        this.defaultValue()
    },
    created() {
        this.getData();
    },
}
</script>

<style lang="sass">
.btn-close-search
    @apply absolute top-0 right-0 flex items-center justify-center
    width: 46px
    height: 46px
.box-find
    width: 100%
    max-width: 400px
    background-color: $color-secondary-500
    border-radius: 4px
    position: relative

.container-box-users
    top: 100%
    z-index: 100
    background-color: $color-secondary-700
    width: 100%

.box-users
    .item-user
        @apply .flex .items-center .p-2 .border-b .cursor-pointer
        &:hover 
            background-color: $color-secondary-600
        &:last-child
            border-bottom: 0px
.box-find-selected
    background-color: $color-secondary-600
    padding-top: 6px
    @apply .absolute .inset-0 .rounded .px-2 .border
    border-color: #2d3061

</style>